import { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Animate } from '@/components/common/Animate';
import { Badge } from '@/components/common/Badge';
import { screen } from '@/components/common/breakpoints';
import { CaptionHeadingDescription } from '@/components/common/CaptionHeadingDescription';
import { RichHeading } from '@/components/common/Heading';
import { Image } from '@/components/common/image';
import { Heading, HeadingLevel, Paragraph } from '@/components/common/MarkUp';
import { Section } from '@/components/layout/Section';
import { getTextColour } from '@/lib/colourUtils';
import { getSanityImageUrl } from '@/lib/sanityUtils';
import { useMediaQuery } from '@/lib/useMediaQuery';

import type {
  BackgroundColourTokenType,
  TextColourTokenType,
} from '@/types/colours';
import type { SanityFeatureShowoffGridType } from '@/types/sanity';
import type { BlockWithAudience } from '@/types/shared';

export interface FeatureShowoffGridProps
  extends SanityFeatureShowoffGridType,
    BlockWithAudience {}

const FeatureCardContainer = ({ card, index, animate }) => {
  const textSafeHeight = 144;
  const featureTextContainer = useRef(null);
  const featCardRef = useRef<HTMLDivElement | null>(null);

  const [extraContent, setExtraContent] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const [textContainerSize, setTextContainerSize] = useState(0);

  const isDesktop = useMediaQuery(screen.smQuery);

  const desktopImage = card?.customImages?.desktopImage;
  const mobileImage = card?.customImages?.mobileImage;

  const cardBackgroundColourToken =
    card?.cardBackgroundColour?.token || '--surface-cool-base';

  const cardImage = isDesktop
    ? desktopImage
    : mobileImage || desktopImage || false;

  useEffect(() => {
    const checkContainerSize = () => {
      const textContainer = featureTextContainer.current;

      if (textContainer) {
        const observer = new ResizeObserver((entries) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const entry of entries) {
            const textContainerHeight =
              entry.target.getBoundingClientRect().height;
            setTextContainerSize(textContainerHeight);

            const heightDiff = textContainerHeight - textSafeHeight;

            if (heightDiff > 0) {
              setExtraContent(heightDiff);
            } else {
              setExtraContent(0);
            }
          }
        });

        observer.observe(textContainer);
      }

      const featCard = featCardRef.current;
      setCardWidth(featCard?.getBoundingClientRect().width);
    };

    checkContainerSize();

    window.addEventListener('resize', checkContainerSize);

    return () => {
      window.removeEventListener('resize', checkContainerSize);
    };
  }, []);

  return (
    <FeatureCardPosition
      className={`item-${index + 1}`}
      settings={animate?.settings}
    >
      <FeatureCard
        key={card._key}
        className={`item-${index + 1}`}
        textAlign={card.textAlign}
        extraHeight={extraContent}
        customImage={!!mobileImage || !!desktopImage}
        ref={featCardRef}
        cardWidth={cardWidth}
        textHeight={textContainerSize}
        backgroundColour={cardBackgroundColourToken}
        fontColour={getTextColour(cardBackgroundColourToken)}
        textBelowImage={card?.textBelowImage}
        fullWidthText={card?.fullWidthText}
      >
        <FeatureTextContainer ref={featureTextContainer}>
          <RichHeading
            size="heading-md"
            elementType="paragraph"
            heading={card.heading}
          />
          <CardDescription
            size="subheading-sm"
            fontColour={getTextColour(cardBackgroundColourToken)}
          >
            {card.description}
          </CardDescription>
          {card.poweredByAI && (
            <BadgeContainer>
              <Badge>POWERED BY AI</Badge>
            </BadgeContainer>
          )}
        </FeatureTextContainer>
        {cardImage && (
          <CustomImageContainer imageAlign={card?.customImages?.imageAlign}>
            <CustomImage
              asset={cardImage}
              alt={card?.customImages?.alt || ''}
              padding={card?.customImages?.padding}
            />
          </CustomImageContainer>
        )}
      </FeatureCard>
    </FeatureCardPosition>
  );
};

const FeatureShowoffGrid = ({
  tag,
  captionHeadingDescription,
  featureCards,
  featureBottomCard,
  animate,
  headingAlignment = 'center',
  backgroundColour,
  pageAudience,
  isAudienceSwitcherEnabled,
}: FeatureShowoffGridProps) => {
  const bottomCardImageAsset =
    featureBottomCard?.image && getSanityImageUrl(featureBottomCard?.image);
  const bottomCardMobileImageAsset =
    featureBottomCard?.mobileImage &&
    getSanityImageUrl(featureBottomCard?.mobileImage);

  const isDesktop = useMediaQuery(screen.smQuery);

  return (
    <Section
      backgroundColour={backgroundColour?.token}
      verticalPadding
      className="feature-showoff-grid"
    >
      <HeadingLevel>
        {captionHeadingDescription && (
          <HeadingContainer
            headingAlignment={headingAlignment}
            settings={animate?.settings}
          >
            {tag?.tagText && (
              <Badge
                bgColour={tag?.tagColour}
                margin="0 0 var(--spacing-xxx-small) 0"
              >
                {tag.tagText}
              </Badge>
            )}
            <StyledCaptionHeadingDescription
              {...captionHeadingDescription}
              desktopAlignment={headingAlignment}
              pageAudience={pageAudience}
              isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
            />
          </HeadingContainer>
        )}
        <FeatureGrid>
          {featureCards.map((card, index) => {
            return (
              <FeatureCardContainer
                key={card._key}
                card={card}
                index={index}
                animate={animate}
              />
            );
          })}
        </FeatureGrid>
        {bottomCardImageAsset && (
          <BottomCard
            settings={animate?.settings}
            asset={
              isDesktop ? bottomCardImageAsset : bottomCardMobileImageAsset
            }
          >
            <TextContainer>
              {isDesktop ? (
                <BackgroundShape
                  src="/images/AppliedCompetencyPlatform/large/Corner-Background-Shape.png"
                  alt=""
                />
              ) : (
                <BackgroundShapeMobile
                  src="/images/AppliedCompetencyPlatform/large/Corner-Background-Shape-Mobile.png"
                  alt=""
                />
              )}
              <TextContent>
                <HeadingLevel>
                  <RichHeading
                    size="heading-md"
                    heading={featureBottomCard.heading}
                    elementType="paragraph"
                  />
                  {featureBottomCard.description && (
                    <CardDescription size="subheading-sm">
                      {featureBottomCard.description}
                    </CardDescription>
                  )}
                </HeadingLevel>
              </TextContent>
            </TextContainer>
          </BottomCard>
        )}
      </HeadingLevel>
    </Section>
  );
};

const CardDescription = styled(Paragraph)<{ fontColour?: TextColourTokenType }>`
  padding-top: var(--spacing-xxx-small);
  width: 100%;
  color: ${({ fontColour }) => `var(${fontColour})`};
`;

const HeadingContainer = styled(Animate)<
  Pick<FeatureShowoffGridProps, 'headingAlignment'>
>`
  display: flex;
  flex-direction: column;

  ${screen.md} {
    align-items: ${({ headingAlignment }) => headingAlignment};
  }
`;

const BadgeContainer = styled.div`
  margin-top: var(--spacing-small);
`;

const StyledCaptionHeadingDescription = styled(CaptionHeadingDescription)`
  margin-bottom: var(--spacing-x-large);
`;

const FeatureTextContainer = styled.div`
  padding: var(--spacing-300);
  position: relative;
  height: fit-content;
  width: 100%;

  ${screen.sm} {
    padding: var(--spacing-350);
  }
  ${screen.md} {
    padding: var(--spacing-400);
  }
`;

const FeatureGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0 var(--spacing-small);

  ${screen.sm} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 944px;
  }
  ${screen.md} {
    gap: 0 var(--spacing-300);
  }
`;

const CustomImageContainer = styled.div<{
  imageAlign: 'left' | 'center' | 'right';
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ imageAlign }) => css`
    align-items: ${imageAlign};
  `}
`;

const CustomImage = styled(Image)<{ padding: boolean }>`
  position: absolute;
  height: auto;
  width: 100%;
  bottom: 0;

  padding: ${({ padding }) => (padding ? '0 var(--spacing-300);' : '')};

  ${screen.sm} {
    padding: ${({ padding }) => (padding ? '0 var(--spacing-350);' : '')};
  }
  ${screen.md} {
    padding: ${({ padding }) => (padding ? '0 var(--spacing-400);' : '')};
  }
`;

const FeatureCardPosition = styled(Animate)`
  position: relative;
  overflow: hidden;
  margin-bottom: var(--spacing-small);

  ${screen.md} {
    margin-bottom: var(--spacing-300);
  }

  &.item-1 {
    grid-column: 1 / span 1;
    grid-row: 1 / span 53;
    position: relative;
  }
  &.item-2 {
    grid-column: 2 / span 1;
    grid-row: 1 / span 47;
  }
  &.item-3 {
    grid-column: 1 / span 1;
    grid-row: 54 / span 47;
  }
  &.item-4 {
    grid-column: 2 / span 1;
    grid-row: 48 / span 53;
  }
`;

const FeatureCard = styled.div<{
  textAlign: 'left' | 'center' | 'right';
  customImage: boolean;
  textHeight: number;
  extraHeight: number;
  cardWidth: number;
  backgroundColour: BackgroundColourTokenType;
  fontColour: TextColourTokenType;
  textBelowImage: boolean;
  fullWidthText: boolean;
}>`
  height: 400px;
  border: 1px solid #2424560a;
  border-radius: var(--radius-l);
  position: relative;
  overflow: hidden;
  background: ${({ backgroundColour }) => `var(${backgroundColour})`};
  color: ${({ fontColour }) => `var(${fontColour})`};

  ::after {
    bottom: ${({ extraHeight }) =>
      extraHeight ? `calc(0px - ${extraHeight}px)` : '0'};
  }
  ${screen.sm} {
    height: 100%;
  }

  ${({ fullWidthText, customImage }) =>
    !customImage
      ? css`
          &.item-1,
          &.item-4 {
            ${screen.md} {
              ${FeatureTextContainer} {
                max-width: 340px;
              }
            }
          }
        `
      : !fullWidthText &&
        css`
          ${screen.md} {
            ${FeatureTextContainer} {
              max-width: 340px;
            }
          }
        `}

  ${({ textBelowImage, textHeight }) =>
    textBelowImage
      ? css`
          display: flex;
          flex-direction: column-reverse;
          img {
            bottom: ${textHeight}px;
          }
        `
      : css`
          display: flex;
          flex-direction: column;
          bottom: 0;
        `};

  /* ------------------------- Image specific styling: ------------------------ */

  /* ------------------------------- First Item: ------------------------------ */
  &.item-1 {
    position: relative;

    ::after {
      mask-size: cover;
      -webkit-mask-size: cover;
      ${({ customImage }) =>
        !customImage &&
        css`
          content: url('/images/AppliedCompetencyPlatform/large/UI-2.svg');
          -webkit-mask-image: url('/images/AppliedCompetencyPlatform/large/UI-2.svg');
        `}
      position: absolute;
      left: 0;
      bottom: ${({ extraHeight }) => `calc(-180px - ${extraHeight}px)`};
    }

    ${screen.sm} {
      ::after {
        bottom: ${({ extraHeight }) => `calc(-100px - ${extraHeight}px)`};
      }
    }

    ${screen.md} {
      ::after {
        mask-size: cover;
        -webkit-mask-size: cover;
        ${({ customImage }) =>
          !customImage &&
          css`
            content: url('/images/AppliedCompetencyPlatform/large/UI-1.svg');
            -webkit-mask-image: url('/images/AppliedCompetencyPlatform/large/UI-1.svg');
          `}
        left: var(--spacing-400);
        position: absolute;
        bottom: ${({ extraHeight }) =>
          extraHeight ? `calc(-50px - ${extraHeight}px)` : '-50px'};
      }
    }
  }

  /* ------------------------------ Second Item: ------------------------------ */

  &.item-2 {
    align-items: center;

    ::after {
      mask-size: cover;
      -webkit-mask-size: cover;
      ${({ customImage }) =>
        !customImage &&
        css`
          content: url('/images/AppliedCompetencyPlatform/large/UI-4.svg');
          -webkit-mask-image: url('/images/AppliedCompetencyPlatform/large/UI-4.svg');
        `}
      position: absolute;
      bottom: ${({ extraHeight }) =>
        extraHeight ? `calc(-100px - ${extraHeight}px)` : '-100px'};
      left: ${({ cardWidth }) => (cardWidth < 523 ? '10px' : 'unset')};
    }

    ${screen.sm} {
      ::after {
        bottom: ${({ extraHeight }) =>
          extraHeight ? `calc(-72px - ${extraHeight}px)` : '-72px'};
      }
    }
  }

  /* ------------------------------- Third Item: ------------------------------ */

  &.item-3 {
    align-items: center;

    ::after {
      mask-size: cover;
      -webkit-mask-size: cover;
      ${({ customImage }) =>
        !customImage &&
        css`
          content: url('/images/AppliedCompetencyPlatform/large/UI-3.svg');
          -webkit-mask-image: url('/images/AppliedCompetencyPlatform/large/UI-3.svg');
        `}
      position: absolute;
      top: ${({ extraHeight }) =>
        extraHeight ? `calc(130px + ${extraHeight}px)` : '130px'};
      right: ${({ cardWidth }) => (cardWidth < 380 ? '-10px' : 'unset')};
    }

    ${screen.md} {
      ::after {
        top: ${({ extraHeight }) =>
          extraHeight ? `calc(110px + ${extraHeight}px)` : '110px'};
      }
    }
  }

  /* ------------------------------ Fourth Item: ------------------------------ */

  &.item-4 {
    display: flex;

    ${({ textBelowImage }) =>
      textBelowImage
        ? css`
            flex-direction: column-reverse;
          `
        : css`
            flex-direction: column;
          `}

    ${({ customImage, textHeight, textBelowImage }) =>
      customImage
        ? textBelowImage
          ? css`
              img {
                bottom: ${textHeight}px;
              }
            `
          : css`
              img {
                top: ${textHeight}px;
              }
            `
        : css`
            justify-content: flex-end;
          `}

    ::before {
      mask-size: cover;
      -webkit-mask-size: cover;
      ${({ customImage }) =>
        !customImage &&
        css`
          content: url('/images/AppliedCompetencyPlatform/large/UI-5.svg');
          -webkit-mask-image: url('/images/AppliedCompetencyPlatform/large/UI-5.svg');
        `}

      position: absolute;
      top: ${({ extraHeight }) =>
        extraHeight ? `calc(-145px - ${extraHeight}px)` : '-145px'};
      left: ${({ cardWidth }) =>
        cardWidth < 414 ? '0' : 'calc(50% - (414px / 2))'};
    }

    ::after {
      mask-size: cover;
      -webkit-mask-size: cover;
      ${({ customImage }) =>
        !customImage &&
        css`
          content: url('/images/AppliedCompetencyPlatform/large/UI-6.svg');
          -webkit-mask-image: url('/images/AppliedCompetencyPlatform/large/UI-6.svg');
        `}
      position: absolute;
      bottom: ${({ extraHeight }) =>
        extraHeight ? `calc(110px + ${extraHeight}px)` : '110px'};
      left: ${({ cardWidth }) =>
        cardWidth < 414 ? '90px' : 'calc(50% - (414px / 2) + 90px)'};
    }

    ${screen.sm} {
      ::before {
        top: ${({ extraHeight }) =>
          extraHeight ? `calc(-70px - ${extraHeight}px)` : '-70px'};
      }
    }

    ${screen.md} {
      ::after {
        bottom: ${({ extraHeight }) =>
          extraHeight ? `calc(90px + ${extraHeight}px)` : '90px'};
        left: calc(50% - (414px / 2) + 280px);
      }
    }
  }

  /* --------------------------- Text Align Switch: --------------------------- */

  ${({ textAlign }) => {
    switch (textAlign) {
      case 'center':
        return css`
          ${FeatureTextContainer} {
            text-align: center;
          }
        `;
      case 'right':
        return css`
          ${FeatureTextContainer} {
            text-align: right;
          }
        `;
      default:
        return css``;
    }
  }}
`;

const BottomCard = styled(Animate)<{ asset }>`
  width: 100%;
  background: ${({ asset }) => `url(${asset})`};
  background-size: cover;
  background-position: center;
  outline: 1px solid var(--colour-border-subtle);
  border-radius: var(--radius-l);
  position: relative;
  overflow: hidden;
  height: 400px;

  ${screen.sm} {
    height: 384px;
  }

  ${CardDescription} {
    position: relative;
    max-width: 368px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const TextContent = styled.div`
  position: absolute;
  padding: var(--spacing-300);
  ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: calc(100% + 20px);
    top: -20px;
    left: 0;
    z-index: 0;
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(180deg);
  }
  ${screen.sm} {
    ::before {
      background-size: contain;
      background-repeat: no-repeat;
      top: 0;
    }
    width: 100%;
    padding: var(--spacing-400);
  }

  ${Heading} {
    max-width: 100%;

    ${screen.sm} {
      max-width: 368px;
    }
  }
`;
const TextContainer = styled.div`
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: 0;
  ${screen.sm} {
    top: 0px;
  }

  ${TextContent} {
    bottom: 0;
    ::before {
      transform: scaleY(-1) scaleX(1);
    }
    ${screen.sm} {
      top: 0px;
      ::before {
        transform: scaleX(1) scaleY(1);
      }
    }
  }
`;

const BackgroundShape = styled.img`
  max-width: 75%;
  ${screen.sm} {
    width: 619px;
    height: 200px;
    max-width: unset;
  }
`;

const BackgroundShapeMobile = styled.img`
  width: 100%;
  height: 200px;
  position: relative;
  bottom: -3px;
`;

export { FeatureShowoffGrid };
